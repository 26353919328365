import axios from 'axios'; // https://github.com/axios/axios
import store from '@/store';
import VueCookies from 'vue-cookies';
/**
 * 포털 API
 */
const APIs = {
  /**
   * 로그 저장 (페이지 전환시 로깅)
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=18416353 Front.공통.로그저장}
   */
  REGIST_LOG: '/api/common/loginfo/regist',

  /**
   * 이메일 중복 체크
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=14876704 Front.회원.이메일 중복체크}
    */
  EMAIL_CHECK: '/api/member/apply/emailcheck',

  /**
   * 소셜 회원 가입
   *
   */
  MEMBER_SNS_JOIN: '/api/member/social/apply',

  /**
   * 소셜 회원 로그인
   * @see {@link https://wiki.dpteam.net/pages/viewpage.action?pageId=14876684 Front.회원.로그인(소셜)}
   */
  MEMBER_SNS_LOGIN: '/api/member/social/login',


  // 회원 정보 조회
  MEMBER_PROFILE: '/api/member/profile',
  // 회원 정보 변경
  MEMBER_UPDATE: '/api/member/update',
  // 회원 탈퇴
  MEMBER_DELETE: '/api/member/drop',
  // 팝업 리스트
  POPUP_LIST: '/api/popup/show',
  // 팝업 설정
  POPUP_CONFIG: '/api/popup/config',
  // 이벤트 리스트
  EVENT_LIST: '/api2/events',
  // 이벤트 리스트(기준날짜 이전이후 이벤트 리스트)
  EVENT_PREV_NEXT_LIST: '/api2/events/sidelist',
  // 이벤트 리스트
  EVENT_LIST_MAIN: '/api/event/getEventListForMain',
  // 이벤트 상세조회
  EVENT_LIST_DETAIL: `/api2/event`,
  // 이벤트 상세(댓글)
  EVENT_DETAIL: '/api/event/detail',
  // 이벤트 신청 목록 조회
  EVENT_APPLY_LIST: `/api2/event/applies`,
  // 이벤트 마이페이지 신청 목록 조회
  EVENT_MY_APPLY_LIST: '/api2/event/my/applies',
  // 이벤트 신청
  EVENT_APPLY: `/api2/event/apply`,
  // 이벤트 신청 (비회원)
  EVENT_APPLY_NONE: `/api2/event/apply`,
  // 이벤트 댓글 추가
  EVENT_REPLY_INSERT: '/api/event/reply',
  // 이벤트 댓글 삭제
  EVENT_REPLY_DELETE: '/api/event/reply/delete',

  // 네트워킹 playerDB 조회
  NETWORKING_PLAYERDB_LIST: '/api/playerdb/search',
  // 네트워킹 player 임시저장 조회
  NETWORKING_PLAYERDB_TEMPORARY: '/api/playerdb/tempsave/search',
  // 네트워킹 player 회사정보 저장
  NETWORKING_PLAYERDB_COMPANY_SAVE: '/api/playerdb/apply',

  // 메시지 발송
  NETWORKING_PLAYERDB_MESSAGE_SEND: '/api/playerdb/message/send',

  // Biz Opportunities 조회
  NETWORKING_BIZOPP_LIST: '/api/bizopp/search',
  // Biz Opportunities 등록
  NETWORKING_BIZOPP_REGISTRY: '/api/bizopp/inquery/regist',
  // Biz Opportunities 삭제
  NETWORKING_BIZOPP_DELETE: '/api/my/bizopp/delete',
  // Biz Opportunities 댓글 추가
  NETWORKING_BIZOPP_REPLY_INSERT: '/api/bizopp/inquery/reply',
  // Biz Opportunities 댓글 삭제
  NETWORKING_BIZOPP_REPLY_DELETE: '/api/bizopp/inquery/reply/delete',

  // Accelerating
  // 임시저장
  ACC_APPLY_TEMPORARY: '/api/acc/selectAccTempInfo',

  ACC_DELETE_TEMPORARY: '/api/acc/deleteAccTempInfo',
  //  acc 데이터
  ACC_APPLY_ACC_INFO: '/api/acc/selectAccInfo',
  // acc 표출항목
  ACC_APPLY_PGM_MNG_DETAIL: '/api/acc/selectAccApplyPgmMng',
  // 공모전 목록 조회
  ACC_APPLY_PGM_MNG_LIST: '/api/acc/selectAccApplyPgmMngList',
  //
  ACC_GET_ACTIVE_TAG_LIST: '/api/acc/getAccActiveTagList',
  //
  ACC_APPLY_INS_ACC_INFO: '/api/acc/saveAccInfo',
  // acc programs 조회
  ACC_PROGRAM_LIST: '/api/acc/mainlist/select',
  // acc programs 상세 조회
  ACC_PROGRAM_DETAIL: '/api/admin/acc/apply/detail',
  // News 조회
  ABOUT_NEWS_LIST: '/api/about/news/search',
  ABOUT_NEWS_LIST_BY_HASHTAG: `/api2/content`,
  // News 상세조회
  ABOUT_NEWS_DETAIL: '/api/about/news/detail',

  // 뉴스레터 목록
  NEWS_LETTER_DETAIL: `/api2/newsLetter`,
  // 뉴스레터 목록
  NEWS_LETTER_LIST: `/api2/newsLetters`,
  // 뉴스레터 구독
  NEWS_LETTER_SUBSCRIPTION: `/api2/newsLetterSubscription`,
  // 구독 상태 확인
  CHECK_SUBSCRIPTION: '/api2/newsLetterSubscription/check/email',
  // HashTag 조회
  HASHTAG_LIST: '/api/common/hashtag/search',

  // 강남센터 포토갤러리
  CENTER_GANGNAM_PHOTO_LIST: '/api/center/photo/search',

  // 강남센터 문의 등록
  CENTER_GANGNAM_INQUIRY_REGISTRY: '/api/my/inquery/regist',
  // 강남센터 문의 조회
  CENTER_GANGNAM_INQUIRY_LIST: '/api/my/inquery/search',
  // 강남센터 문의 삭제
  CENTER_GANGNAM_INQUIRY_DELETE: '/api/my/inquery/delete',
  // 강남센터 대관신청 조회
  CENTER_GANGNAM_VENUE_LIST: `/api2/venue/reservations`,
  // 강남센터 대관신청
  CENTER_GANGNAM_VENUE_APPLY: `/api2/venue/reservation`,
  // 강남센터 대관 휴일 조회
  CENTER_GANGNAM_VENUE_HOLIDAY: `/api2/venue/holidays`,

  // My Page 플레이어 디비 조회
  MYPAGE_PLAYERDB_LIST: '/api/my/playerdb/search',
  // My Page 플레이어 디비 삭제
  MYPAGE_PLAYERDB_DELETE: '/api/my/playerdb/delete',
  // My Page Biz Opportunities 조회
  MYPAGE_BIZOPP_LIST: '/api/my/bizopp/search',
  // My Page 메시지 조회
  MYPAGE_MESSAGE_LIST: '/api/my/message/search',
  // My Page 메시지 삭제
  MYPAGE_MESSAGE_DELETE: '/api/my/message/delete',
  // My Page 이벤트 조회
  MYPAGE_EVENT_LIST: '/api/my/event/search',
  // My Page 이벤트 취소
  MYPAGE_EVENT_CANCEL: `/api2/event/apply`,
  // My Page 댓글 조회
  MYPAGE_REPLY_LIST: '/api/my/reply/search',
  // My Page 댓글 삭제
  MYPAGE_REPLY_DELETE: '/api/my/reply/delete',
  // My Page ACC 조회
  MYPAGE_PROGRAM_LIST: '/api/acc/mypage/select',
  // My Page ACC 취소
  MYPAGE_PROGRAM_CANCEL: '/api/acc/accinfo/cancel',
  // My Page 대관 조회
  MYPAGE_VENUE_LIST: `/api2/venue/reservations`,
  // My Page 대관 취소
  MYPAGE_VENUE_CANCEL: `/api2/venue/reservation`,
  // 국가 코드 조회
  SEARCH_COUNTRY_CODE: '/api/common/countrycode/search',
  // 국가 코드 조회
  COUNT_COM_REG_NO: '/api/playerdb/comregno/check',
  // 아카데미 수강신청
  UPDATE_ACADEMY_INFO: '/api/member/updateacademyinfo',
  // 아카데미 수강동영상 컨트롤
  REGIST_COURSE_PRGRS: '/api/academy/registcourseprgrs',
  // 아카데미 수강신청 내역
  SEARCH_COURSE_PRGRS_LIST: '/api/academy/searchcourseprgrslist',
  // 방문예약 불가 날짜 조회
  EVENTVISIT_LIST: '/api/eventvisit/search',
  // rsa 퍼블릭키 조회
  RSA_PUBLICKEY: '/api/common/rsa/publickey',
  // 컨텐츠 조회
  CONTENTS_LIST: `/api2/contents`,
  // 컨텐츠 조회(상단 고정)
  CONTENTS_FIX: '/api/about/contents/searchfix',
  // 컨텐츠 상세
  CONTENTS_DETAIL: `/api2/content`,
  // 컨텐츠 조회(연관 컨텐츠)
  CONTENTS_LIST_PIPE: '/api/about/contents/pipe',
  // 컨텐츠 조회(인기 컨텐츠)
  CONTENTS_LIST_POPU: '/api/about/contents/popularity',
  // 공모전 목록 조회
  CONTEST_LIST: '/api2/competitions',
  // 공모전
  CONTEST_API: '/api2/competition',
};

/**
 * 포털 파일 API
 */
const FileAPIs = {
  /**
   * 파일 업로드
   * @see {@link https://git.dpteam.net/DPD_Group/ecohub_server/
   *          blob/master/src/main/java/com/dreamplus/ecohub/controller/ControllerExternalUtil.java#L54}
   */
  UPLOAD_FILE: '/api/fileupload/uploadForm',

  /**
   * 파일 삭제
   * @see {@link https://git.dpteam.net/DPD_Group/ecohub_server/
   *          blob/master/src/main/java/com/dreamplus/ecohub/controller/ControllerExternalUtil.java#L224}
   */
  REMOVE_FILE: '/api/fileupload/deleteObject',
};

/**
 * 포털 파일 경로
 * - CDN (AWS CloudFront, S3)
 */
const FilePaths = {
  CENTER: '/center',
  EVENT: '/event',
  ACCELERATING: '/accelerating',
  NETWORKING: '/networking',
  MESSAGE: '/message',
  PHOTO: '/photo',
  USER: '/user',
  NEWS: '/news',
  ETC: '/etc',
  POPUP: '/popup',
  MEDIA: '/media',
  CONTENT: '/content',
  COMPETITION: '/competition',
  BROCHURE_EN: '/brochure/en',
  BROCHURE_KO: '/brochure/ko',
};

const FileCategory = {
  EVENTHALL: 16  
};

/**
 * 강남 센터 API
 */
const CenterGangnamAPIs = {
  // 방문예약조회
  VISIT_RESERVATION_SEARCH: `/api/v3/visitors/summaryByHour`,
  // 방문예약하기
  VISIT_RESERVATION: `/api/v3/visitors`,
};

/**
 * 포털 API Client
 * @param {string} token
 * @param {string} fingerprint
 * @param {function(import('axios').AxiosError): Promise<never>} errorHandler
 * @returns {function(import('axios').AxiosRequestConfig): import('axios').AxiosPromise} AxiosPromise
 */
function getPortalApiClient(token, fingerprint, errorHandler, method = 'post') {
  // axios instance
  const instance = axios.create({
    baseURL: process.env.VUE_APP_URI_PORTAL_API,
    // method: 'post',
    method: method,
    validateStatus(status) {
      if (status === 999) {
        return false;
      }
      return (status >= 200 && status < 300) || (status >= 600);
    },
  });

  // axios interceptors : https://github.com/axios/axios#interceptors
  instance.interceptors.request.use(
    (config) => {
      if (token) {
        store.dispatch('updateLogoutTimeout', null)
      }
      config.headers = { ...config.headers, Authorization: token };
      if (config.data instanceof FormData) {
        config.data.append('finger_print', fingerprint);
      } else {
        config.data = { ...config.data, finger_print: fingerprint };
      }
      return config;
    },
    (error) => Promise.reject(error));

  instance.interceptors.response.use((res)=>{
    if (res.data && res.data.result == false && res.data.code == '301') {
      // 301 코드
      // 로그아웃 하지 않고 종료 후
      // 만료된 토큰 정보가 남아있을 경우 로그인 데이터를 지우고 새로고침
      store.commit('setOauthUserInfo', undefined);
      store.commit('setUserInfo', undefined);
      VueCookies.remove('ecohubtoken', '/');
      window.location.reload();
    }

    if (res.data && res.data.result == false && res.data.code == '999') {
      VueCookies.remove('ecohubtoken', '/');
      window.location.href = '/signout';
    }

    return res;
  })

  return (options) => instance(options)
    .catch(errorHandler);
}

export {
  APIs,
  FileAPIs,
  FilePaths,
  FileCategory,
  CenterGangnamAPIs,
  getPortalApiClient,
};
